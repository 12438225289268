import Navbar from "../components/Navbar";
import styles from "../../styles/layouts/MainLayout.module.scss";
interface IProps {
  children: React.ReactNode;
}
const MainLayout = ({ children }: IProps) => {
  return (
    <div className={styles.dashLayout}>
      <Navbar />
      <div className={styles.dashPage}>{children}</div>
    </div>
  );
};

export default MainLayout;
