import { useState } from "react";
import { AiOutlineMenu } from "react-icons/ai";
import { Link as RedireactLink } from "react-scroll";

import logo from "../../assets/images/logo.svg";
import styles from "../../styles/component/Navbar.module.scss";
interface IProps {
  type?: string;
}
const Navbar = ({ type }: IProps) => {
  const [mobileNavbarOpen, setMobileNavbarOpen] = useState(false);
  const handleToggleNavbar = () => {
    setMobileNavbarOpen(!mobileNavbarOpen);
  };
  return (
    <header className={styles.header}>
      <div className={styles.logo}>
        <RedireactLink to="main">
          <img src={logo} alt="" />
        </RedireactLink>
      </div>
      <button className={styles.toggleBtn} onClick={handleToggleNavbar}>
        <AiOutlineMenu />
      </button>
      <div
        className={`${styles.overlay} ${mobileNavbarOpen ? styles.show : ""}`}
        onClick={handleToggleNavbar}
      />
      <div
        className={`${styles.navbar_wrapper} ${
          mobileNavbarOpen ? styles.open : ""
        }`}
      >
        <div className={styles.navbar}>
          <RedireactLink to="main">
            <span>Home</span>
          </RedireactLink>
          <RedireactLink to="aboutUs">
            <span>About Us</span>
          </RedireactLink>
          <RedireactLink to="features">
            <span>Features</span>
          </RedireactLink>
          <RedireactLink to="contactUs">
            <span>Contact Us</span>
          </RedireactLink>
          <a
            href="https://portal.draughtguardian.com/"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            <span>Login</span>
          </a>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
