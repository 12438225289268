import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-scroll";
import axios from "axios";

const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  message: "",
};

interface IPropsValues {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  message: string;
}
const GetInTouch = ({ setIsSubmitted, setSubmittedMsg }: any) => {
  const [values, setValues] = useState<IPropsValues>(initialValues);
  const [errors, setErrors] = useState<IPropsValues>(initialValues);
  const handleOnChange = (event: any) => {
    setValues((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
    if (!event.target.value) {
      setErrors((prev) => ({
        ...prev,
        [event.target.name]: "Above field is required",
      }));
    } else {
      setErrors((prev) => ({
        ...prev,
        [event.target.name]: "",
      }));
    }
  };

  const isFormValid = () => {
    let isValid = true;
    setErrors(initialValues);
    const fields = ["firstName", "lastName", "phone", "email", "message"];

    for (let x of fields) {
      if (!values[x as keyof IPropsValues]) {
        isValid = false;
        setErrors((errors) => ({
          ...errors,
          [x]: "Above field is required.",
        }));
      }
    }
    return isValid;
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (!isFormValid()) {
      return false;
    } else {
      let newRes: any = await axios({
        method: "post",
        url: `https://draughtguardian-api.com:9705/api/auth/contact-us`,
        data: values,
      })
        .then((response) => response)
        .catch((err) => err.response);
      if (newRes?.data.status_code) {
        setIsSubmitted(true);
        setSubmittedMsg(newRes?.data?.message);
        setValues({
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          message: "",
        });
      } else {
        setSubmittedMsg(newRes?.data?.message);
        setIsSubmitted(false);
      }
    }
  };
  return (
    <form onSubmit={handleSubmit} method="post">
      <div className={`row`}>
        <div className={`col-lg-6`}>
          <div className="form-floating text-start mb-3">
            <input
              type="text"
              className={`form-control ${errors.firstName ? "is-invalid" : ""}`}
              id="floatingInput"
              name="firstName"
              value={values.firstName}
              placeholder="First Name"
              onChange={handleOnChange}
            />
            {errors.firstName ? (
              <small className="text-danger">{errors.firstName}</small>
            ) : (
              ""
            )}
            <label>First Name</label>
          </div>
        </div>
        <div className={`col-lg-6`}>
          <div className="form-floating text-start mb-3">
            <input
              type="text"
              className={`form-control ${errors.lastName ? "is-invalid" : ""}`}
              name="lastName"
              value={values.lastName}
              placeholder="Last Name"
              onChange={handleOnChange}
            />
            {errors.lastName ? (
              <small className="text-danger">{errors.lastName}</small>
            ) : (
              ""
            )}
            <label>Last Name </label>
          </div>
        </div>
        <div className={`col-lg-12`}>
          <div className="form-floating text-start mb-3">
            <input
              type="number"
              className={`form-control ${errors.phone ? "is-invalid" : ""}`}
              name="phone"
              value={values.phone}
              placeholder="Phone Number"
              onChange={handleOnChange}
            />
            {errors.phone ? (
              <small className="text-danger">{errors.phone}</small>
            ) : (
              ""
            )}
            <label>Phone</label>
          </div>
        </div>
        <div className={`col-lg-12`}>
          <div className="form-floating text-start mb-3">
            <input
              type="email"
              className={`form-control ${errors.email ? "is-invalid" : ""}`}
              name="email"
              value={values.email}
              placeholder="Email Address"
              onChange={handleOnChange}
            />
            {errors.email ? (
              <small className="text-danger">{errors.email}</small>
            ) : (
              ""
            )}
            <label>Email</label>
          </div>
        </div>
        <div className={`col-lg-12`}>
          <div className="form-floating text-start">
            <textarea
              className={`form-control ${errors.message ? "is-invalid" : ""}`}
              placeholder="Leave a comment here"
              id="floatingTextarea2"
              name="message"
              value={values.message}
              onChange={handleOnChange}
            ></textarea>
            {errors.message ? (
              <small className="text-danger">{errors.message}</small>
            ) : (
              ""
            )}
            <label>Message</label>
          </div>
        </div>
        <div>
          <button type="submit">Submit</button>
        </div>
      </div>
    </form>
  );
};

export default GetInTouch;
