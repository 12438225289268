import AwesomeSlider from "react-awesome-slider";
import "react-awesome-slider/dist/styles.css";
import { Container } from "react-bootstrap";
import bannerImg from "../../assets/images/banner.jpg";
import Beer from "../../assets/icons/pourImg.png";
import Email from "../../assets/icons/alertImg.png";
import logo from "../../assets/images/logo.svg";
import Monitoring from "../../assets/icons/monitorImg.png";
import Waste from "../../assets/icons/reportImg.png";
import Utilisation from "../../assets/icons/assetImg.png";
import AppIcon from "../../assets/images/app.png";
import HuaweiIcon from "../../assets/images/huawei.png";
import PlaystoreIcon from "../../assets/images/playstore.png";
import Webicon from "../../assets/icons/DashboardImg.png";
import DashboardLayout from "../../common/layouts/MainLayout";
import styles from "../../styles/pageStyle/Home.module.scss";
import GetInTouch from "../../common/components/GetInTouch";
import newOne from "../../assets/images/new-one.png";
import newTwo from "../../assets/images/new-two.png";

import { Link as RedireactLink } from "react-scroll";
import DgLogo from "../../common/components/DgLogo";
import { useEffect, useState } from "react";
import MapImg from "../../assets/images/New-map-img.png";
import RefrenceImg from "../../assets/images/img-2.png";
import VariableHistoryImg from "../../assets/images/img-3.png";
import DetailedHistoryImg from "../../assets/images/img-4.png";
import ShiftReportImg from "../../assets/images/img-5.png";

function Home() {
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [isSubmited, setIsSubmitted] = useState<boolean>(false);
  const [submittedMsg, setSubmittedMsg] = useState<string>("");

  function getWindowSize() {
    return window.innerWidth;
  }

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  return (
    <DashboardLayout>
      <div
        className={styles.banner}
        style={{ backgroundImage: `url(${bannerImg})` }}
        id="main"
      >
        <Container>
          <div className={styles.banner_content}>
            <div className={styles.banner_left_content}>
              <DgLogo />
              {/* <img src={logoDg} alt="lines" /> */}
            </div>
            {/* <p>if you can measure it, you can improve it </p> */}
            <p style={{ marginTop: "10px" }}>
              Draught Guardian is a web-based monitoring solution designed to
              increase your draft beer profitability immediately!
            </p>
          </div>
        </Container>
      </div>
      <div id="aboutUs">
        <Container>
          <div className={styles.aboutUs}>
            <div className={styles.leftSide}>
              <div className={`card mb-3 ${styles.customCard}`}>
                <div className={`row g-0 align-items-center`}>
                  <div className={`col-lg-5`}>
                    <div className={`card-body ${styles.customCardbody}`}>
                      <h5 className={`card-title ${styles.customCardtitle}`}>
                        Start improving today
                      </h5>
                      <p className={`card-text ${styles.customCardtext}`}>
                        Draught Guardian is a technology company who Design,
                        Develop and Maintain telemetry systems and associated
                        reporting software for the liquor industry globally.
                        Draught Guardian’s proprietary technology has been
                        developed and continues to develop through our years of
                        experience with Breweries.
                      </p>

                      {/* <div className={styles.customCardbtn}>
                        <RedireactLink to="contactUs">
                          <button>Learn More</button>
                        </RedireactLink>
                      </div> */}
                    </div>
                  </div>
                  <div className="col-md-7">
                    <img
                      src={newOne}
                      className="img-fluid rounded-start"
                      alt="..."
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.rightSide}></div>
          </div>
        </Container>
        <div className={styles.aboutbg}>
          <Container>
            <div className={styles.aboutApp}>
              <div className={`row g-5 align-items-center`}>
                <div className={`col-lg-6`}>
                  <img src={newTwo} alt="" />
                </div>
                <div className={`col-lg-6`}>
                  <div className={`card-body ${styles.customCardbody}`}>
                    <h5 className={`card-title ${styles.customCardtitle}`}>
                      Actionable data at your fingertips
                    </h5>
                    <h6>KPI monitoring – faster & smarter!</h6>
                    <p className={`card-text ${styles.customCardtext}`}>
                      More so now than ever in the history is access to
                      Real-Time actionable data critical to the survival and
                      growth of FMCG businesses. Draught Guardian provides
                      Breweries and users alike the ability to monitor and
                      manipulate the data received.
                    </p>
                    {/* <p className={`card-text ${styles.customCardtext}`}>
                      As management, you can forward your messages to the
                      management app group if the issue needs to be escalated,
                      so everyone can have their say.
                    </p> */}
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>
      <div className={styles.featureSection} id="features">
        <div className={styles.featurebg}>
          <div className={styles.feature}>
            <AwesomeSlider
              organic-arrow-height="10px"
              animation="cubeAnimation"
              style={{
                "--slider-height-percentage":
                  windowSize < 679 ? "62%" : windowSize < 1441 ? "49%" : "35%",
              }}
              bullets={false}
            >
              <div
                style={{ background: "transparent", color: "#fff" }}
                className={styles.slideItem}
              >
                <div className={styles.slideItemContent}>
                  <h4>MAPPING</h4>
                  <img src={MapImg} alt="" />

                  <p>
                    Live asset mapping with online indication and easy hover
                    asset stats
                  </p>
                </div>
              </div>
              <div
                style={{ background: "transparent", color: "#fff" }}
                className={styles.slideItem}
              >
                <div className={styles.slideItemContent}>
                  <h4>SHIFT REPORTS</h4>
                  <img src={ShiftReportImg} alt="" />
                  <p>
                    Downloadable shift reports to allow for easy sales vs actual
                    analysis
                  </p>
                </div>
              </div>
              <div
                style={{ background: "transparent", color: "#fff" }}
                className={styles.slideItem}
              >
                <div className={styles.slideItemContent}>
                  <h4>VARIABLE HISTORY REPORT</h4>
                  <img src={VariableHistoryImg} alt="" />
                  <p>Clearly presented easy to follow reports</p>
                </div>
              </div>
              <div
                style={{ background: "transparent", color: "#fff" }}
                className={styles.slideItem}
              >
                <div className={styles.slideItemContent}>
                  <h4>QUICK REFERENCE CUSTOMISABLE DASHBOARD</h4>
                  <img src={RefrenceImg} alt="" />
                  <p>
                    Clickable links throughout the system Allowing easy
                    intuitive navigation
                  </p>
                </div>
              </div>
              <div
                style={{ background: "transparent", color: "#fff" }}
                className={styles.slideItem}
              >
                <div className={styles.slideItemContent}>
                  <h4>DETAILED HISTORY</h4>
                  <img src={DetailedHistoryImg} alt="" />
                  <p>
                    Flow data reports by the month/day/hour or even individual
                    pours
                  </p>
                </div>
              </div>
            </AwesomeSlider>
          </div>
        </div>
        <Container>
          <div className={`my-5`}>
            <div className={`text-center my-lg-4`}>
              <h2 className={styles.textLarge}>Benefits and features:</h2>
              <p style={{ textAlign: "center" }}>
                <span style={{ fontWeight: "400" }}>The Consumer</span> will
                enjoy an enhanced beer experience through always fresh, cold
                Beer, poured through timeously serviced lines.
                <br />
                <p style={{ paddingBottom: "10px" }}></p>
                <span style={{ fontWeight: "400", textAlign: "center" }}>
                  The Outlet
                </span>{" "}
                will enjoy increased revenue through reduced wastage and
                shrinkage (theft), resulting in more sales AND more profitable
                sales. Unaccounted pours amount to as much as 23% of a keg.
              </p>
            </div>
            <div className={`col-lg-8 mx-auto`}>
              <div className={`row`}>
                <div className={`col-lg-6 my-4`}>
                  <div className={styles.featureCards}>
                    <img src={Beer} alt="" width="80px" />
                    <div className={`ps-3`}>
                      <h4>Real time pours and volume</h4>
                      <p>
                        Actionable volume data per Outlet, Brand, Unit size and
                        Date range
                      </p>
                    </div>
                  </div>
                </div>
                <div className={`col-lg-6  my-4`}>
                  <div className={styles.featureCards}>
                    <img src={Waste} alt="" width="80px" />
                    <div className={`ps-3`}>
                      <h4>Wastage reporting</h4>
                      <p>
                        Eliminate theft, unaccounted pours amount to as much as
                        23% of a keg
                      </p>
                    </div>
                  </div>
                </div>
                <div className={`col-lg-6 my-4`}>
                  <div className={styles.featureCards}>
                    <img src={Monitoring} alt="" width="80px" />
                    <div className={`ps-3`}>
                      <h4>Line Clean Monitoring</h4>
                      <p>
                        Ensuring lines are cleaned timeously to enable excellent
                        quality product
                      </p>
                    </div>
                  </div>
                </div>
                <div className={`col-lg-6  my-4`}>
                  <div className={styles.featureCards}>
                    <img src={Utilisation} alt="" width="80px" />
                    <div className={`ps-3`}>
                      <h4>Asset utilisation</h4>
                      <p>
                        Efficient utilisation of equipment, optimise return on
                        investment
                      </p>
                    </div>
                  </div>
                </div>
                <div className={`col-lg-6 my-4`}>
                  <div className={styles.featureCards}>
                    <img src={Webicon} alt="" width="80px" />
                    <div className={`ps-3`}>
                      <h4>Dashboards</h4>
                      <p>
                        Insightful user-friendly dashboards and customisable
                        volume reporting
                      </p>
                    </div>
                  </div>
                </div>
                <div className={`col-lg-6  my-4`}>
                  <div className={styles.featureCards}>
                    <img src={Email} alt="" width="80px" />
                    <div className={`ps-3`}>
                      <h4>Configurable alerts</h4>
                      <p>
                        Automated reports and alerts set per your specific
                        exception criteria
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <div className={styles.getInTouch} id="contactUs">
        <div className={`col-lg-7 mx-auto text-center d-flex`}>
          <div className={styles.customForm}>
            <div className={styles.contactForm}>
              {isSubmited && <p>{submittedMsg}</p>}

              <span>Stay In Contact</span>
              <h5>Get In Touch</h5>
              <div>
                <GetInTouch
                  setIsSubmitted={setIsSubmitted}
                  setSubmittedMsg={setSubmittedMsg}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <Container>
          <div className={`row`}>
            <div className={`col-md-4 offset-md-4 text-center`}>
              <div className={styles.flex}>
                <img src={logo} alt="" />
                <p className="pt-0">info@draughtguardian.com </p>
                <div className={styles.icons}>
                  <p style={{ marginBottom: "-10px" }}>
                    Application available on all mobile stores
                  </p>
                  <div className="d-flex justify-content-center">
                    <div className={styles.social_icon}>
                      <a
                        target="_blank"
                        href='https://apps.apple.com/za/app/draught-guardian/id1561012657" rel="noreferrer" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://apps.apple.com/za/app/draught-guardian/id1561012657&amp;source=gmail&amp;ust=1652874772838000&amp;usg=AOvVaw2W8YaLAF-6nli-O_DjM411'
                        rel="noreferrer"
                      >
                        <img src={AppIcon} alt="" />
                      </a>
                      <a href='https://appgallery.huawei.com/app/C104827183" rel="noreferrer" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://appgallery.huawei.com/app/C104827183&amp;source=gmail&amp;ust=1652874772838000&amp;usg=AOvVaw1_sxClId4UCgSO-prUtcps'>
                        <img src={HuaweiIcon} alt="" />
                      </a>
                      <a href="https://www.google.com/url?q=https://play.google.com/store/apps/details?id%3Dcom.draughtguardian.draughtguardian%26gl%3DZA&amp;source=gmail&amp;ust=1652874772838000&amp;usg=AOvVaw2yP8H93xf6QmXAvRuzwgy_">
                        <img src={PlaystoreIcon} alt="" />
                      </a>
                    </div>
                  </div>
                  <div className={styles.socialIconFooter}>
                    {/* <svg
                      width="64"
                      height="64"
                      viewBox="0 0 200 200"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0 0V150H150V0H0ZM99.2321 41.2803H87.5077C85.1855 41.2803 82.8419 43.6803 82.8419 45.4658V57.421H99.209C98.55 66.5881 97.197 74.971 97.197 74.971H82.7581V126.923H61.2419V74.9654H50.7679V57.4833H61.2419V43.1901C61.2419 40.5765 60.7124 23.0773 83.2876 23.0773H99.2324V41.2803H99.2321Z"
                        fill="#FBAF24"
                      />
                    </svg> */}
                    <svg
                      width="64px"
                      height="64px"
                      viewBox="0 0 41 58"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <a
                        href="https://www.linkedin.com/company/draught-gaurdian"
                        target="_blank"
                      >
                        <path
                          d="M20.9716667,33.5527338 L25.001,33.5527338 L25.001,27.1328007 C25.001,25.439485 25.3213333,23.7988354 27.4206667,23.7988354 C29.491,23.7988354 29.517,25.7351486 29.517,27.2404662 L29.517,33.5527338 L33.5506667,33.5527338 L33.5506667,26.4341413 C33.5506667,22.9381777 32.796,20.2505391 28.711,20.2505391 C26.7483333,20.2505391 25.432,21.3265278 24.8943333,22.3471839 L24.839,22.3471839 L24.839,20.5725357 L20.9716667,20.5725357 L20.9716667,33.5527338 Z M16.423,14.1202696 C15.1273333,14.1202696 14.0823333,15.1682587 14.0823333,16.4595785 C14.0823333,17.7508984 15.1273333,18.7992208 16.423,18.7992208 C17.7133333,18.7992208 18.761,17.7508984 18.761,16.4595785 C18.761,15.1682587 17.7133333,14.1202696 16.423,14.1202696 L16.423,14.1202696 Z M14.4026667,33.5527338 L18.4406667,33.5527338 L18.4406667,20.5725357 L14.4026667,20.5725357 L14.4026667,33.5527338 Z M9.76633333,40 C8.79033333,40 8,39.2090082 8,38.2336851 L8,9.76631493 C8,8.79065843 8.79033333,8 9.76633333,8 L38.234,8 C39.2093333,8 40,8.79065843 40,9.76631493 L40,38.2336851 C40,39.2090082 39.2093333,40 38.234,40 L9.76633333,40 Z"
                          id="Shape"
                          fill="#FBAF24"
                        />
                      </a>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </DashboardLayout>
  );
}
export default Home;
