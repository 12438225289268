import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Route, Routes } from "react-router-dom";
import NotFound from "./pages/404";
import Home from "./pages/Home";
import "./styles/App.scss";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
